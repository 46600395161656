<template>
	<div class="row mt2" id="checkout-row">
		<div class="col-md-8">
			<div id="checkout">
				<div class="card">
					<a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
						<div class="card-header" v-bind:class="errors.payment_id?'border-error':'' ">
					  		<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path d="M22 2h-14c-1.104 0-2 .896-2 2v4h16v3.5c0 .276-.224.5-.5.5h-1.5v2h2c1.104 0 2-.896 2-2v-8c0-1.104-.896-2-2-2zm0 3h-14v-.5c0-.276.224-.5.5-.5h13c.276 0 .5.224.5.5v.5zm-6 5h-14c-1.104 0-2 .896-2 2v8c0 1.104.896 2 2 2h14c1.104 0 2-.896 2-2v-8c0-1.104-.896-2-2-2zm-11 10h-2v-1h2v1zm3 0h-2v-1h2v1zm.32-3.377c-.383.239-.836.377-1.32.377-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5c.484 0 .937.138 1.32.377-.531.552-.857 1.3-.857 2.123 0 .824.326 1.571.857 2.123zm3.68 3.377h-2v-1h2v1zm-1-3c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zm4 3h-2v-1h2v1z"/></svg>
					  		<span>Metoda płatności</span>
						</div>
					</a>
					<div id="collapseThree" class="collapse show" data-parent="#checkout">
						<div class="card-body">
							<table class="table">
					    	   	<tbody>
				    	        	<tr v-for="payment in payments">
			    	            		<td>				    	            			
			    	            			<label :for="payment.name">
			    	            				<div class="radiotext select-option">
			    	            					<input type="radio" @change="selectPayment(payment)" :id="payment.name" name="payment" :value="payment.id" >					
			    	            				</div>
			    	            			</label>
										</td>
			    	            		<td>
											<label :for="payment.name">
												<div class="radiotext">
			    	            					{{ payment.label }}
												</div>
											</label>
										</td>										
				    	        	</tr>
					    	    </tbody>
					    	</table>
						</div>
					</div>
				</div>
				<div class="card">
					<a class="card-link" data-toggle="collapse" href="#collapseOne">
				    	<div class="card-header" v-bind:class="errors.courier_id?'border-error':''"">
					    	<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path d="M17.677 16.879l-.343.195v-1.717l.343-.195v1.717zm2.823-3.324l-.342.195v1.717l.342-.196v-1.716zm3.5-7.602v11.507l-9.75 5.54-10.25-4.989v-11.507l9.767-5.504 10.233 4.953zm-11.846-1.757l7.022 3.2 1.7-.917-7.113-3.193-1.609.91zm.846 7.703l-7-3.24v8.19l7 3.148v-8.098zm3.021-2.809l-6.818-3.24-2.045 1.168 6.859 3.161 2.004-1.089zm5.979-.943l-2 1.078v2.786l-3 1.688v-2.856l-2 1.078v8.362l7-3.985v-8.151zm-4.907 7.348l-.349.199v1.713l.349-.195v-1.717zm1.405-.8l-.344.196v1.717l.344-.196v-1.717zm.574-.327l-.343.195v1.717l.343-.195v-1.717zm.584-.332l-.35.199v1.717l.35-.199v-1.717zm-16.656-4.036h-2v1h2v-1zm0 2h-3v1h3v-1zm0 2h-2v1h2v-1z"/></svg>
					    	<span>Dostawa</span>
				  		</div>
					</a>
				  	<div id="collapseOne" class="collapse" data-parent="#checkout">
					    <div class="card-body">				    				    
					    	<table class="table">
					    		<thead>
					    			<th></th>
					    			<th></th>
					    			<th>Czas</th>
					    			<th>Koszt</th>
					    		</thead>
					    	   <tbody>
				    	   			<tr v-for="courier in couriers" v-if="courier.show">
			    	            		<td>				    	            			
			    	            			<label :for="courier.name.toLowerCase()">
			    	            				<div class="radiotext select-option">
			    	            					<input type="radio" v-model="courier_id" @change="selectCourier(courier)" :id="courier.name.toLowerCase()" name="courier" :value="courier.id" 
			    	            					>
			    	            				</div>
			    	            			</label>
										</td>
			    	            		<td>
											<label :for="courier.name.toLowerCase()">
												<div class="radiotext">
			    	            					{{ courier.name }}
			    	            					<button v-show="courier.type == 'INPOST'" class="btn-sm btn btn-outline-warning" @click="openInpostModal()">Mapa</button>
												</div>
											</label>
										</td>
										<td>
											<label :for="courier.name.toLowerCase()">
			    	            				<div class="radiotext">
			    	            					{{ courier.delivery_time_label }}
			    	            				</div>
			    	            			</label>
										</td>
										<td>
											<label :for="courier.name.toLowerCase()">
			    	            				<div class="radiotext">
													<p v-if="courier.free_delivery == '' || (parseFloat(courier.free_delivery) < parseFloat(cartTotal))">
														Darmowa dostawa
													</p>
													<p v-else>
					    	            				{{ courier.price }} zł
													</p>														
						    	            		</div>
					    	            	</label>
										</td>
				    	        	</tr>
					    	    </tbody>
					    	</table>
				    		<div class="row" v-show="displayDeliveryCalendar" id="delivery-calendar">
				    			<div class="col-md-6">				    				
				    				<label>Wybierz termin odbioru zamówienia w sklepie</label>
				    				<input @change="checkDeliveryCalendar()" class="form-control" type="date" v-model="pickupDate" name="pickup_date" :min="pickupDateMin" required>
				    			</div>
		    					<div class="col-md-6" v-show="dayAvailable">
		    						<label>Czas odbioru</label>
		    						<div class="input-group">
			    						<input @change="checkOpenHour()" class="form-control" type="number" step=1 v-model="pickupTimeHour" name="pickup_time_hour" :min="pickupTimeHourMin" :max="pickupTimeHourMax" required>	
	    								<span class="input-group-addon">:</span>
			    						<input @change="checkMinutes()" class="form-control" type="number" step=1 v-model="pickupTimeMinute" name="pickup_time_minute" :min="pickupTimeMinuteMin" :max="pickupTimeMinuteMax" required>
									</div>
		    						<p> Godziny otwarcia: <span v-html="shopInfo"></span></p>			    						
		    					</div>
		    					<div class="col-md-6" v-show="!dayAvailable">
		    						<br>
		    						Nasz sklep jest nieczynny w wybrany dzień.
		    					</div>
				    		</div>
					    </div>
				  	</div>
				</div>					
				<div class="card">
				   	<a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
				  		<div class="card-header" 
				  		v-bind:class="errors.name||errors.surname||errors.email||errors.phone||errors.address||errors.postcode||errors.city?'border-error':''">
				    		<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path d="M12 3c2.131 0 4 1.73 4 3.702 0 2.05-1.714 4.941-4 8.561-2.286-3.62-4-6.511-4-8.561 0-1.972 1.869-3.702 4-3.702zm0-2c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm8 6h-3.135c-.385.641-.798 1.309-1.232 2h3.131l.5 1h-4.264l-.344.544-.289.456h.558l.858 2h-7.488l.858-2h.479l-.289-.456-.343-.544h-2.042l-1.011-1h2.42c-.435-.691-.848-1.359-1.232-2h-3.135l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2h3.75l1 2h-3.892l-.858-2z"/></svg>
				    		<span>Dane klienta</span>
				  		</div>
				  	</a>
				  	<div id="collapseTwo" class="collapse" data-parent="#checkout">
				    	<div class="card-body">
		      		        <div class="form-group mt-3">
			    				<label for="">Imię</label>
		      		            <input  v-on:keydown="updateErrors('name')" type="text" placeholder="Imię" class="form-control" name="name" v-model="user.name" v-bind:class="errors.name?'border-error':''" autofocus>
		      		        </div>
		      		        <div class="form-group">
			    				<label for="">Nazwisko</label>
		      		            <input  v-on:keydown="updateErrors('surname')" id="surname" type="text" placeholder="Nazwisko" class="form-control" name="surname" v-model="user.surname" v-bind:class="errors.surname?'border-error':''" >
		      		        </div>
		      		        <div class="form-group">
			    				<label for="">Email</label>
		      		            <input  v-on:keydown="updateErrors('email')" type="email" class="form-control" id="email" name="email" v-model="user.email" v-bind:class="errors.email?'border-error':''"  placeholder="Email">
		      		        </div>
		      		        <div class="form-group">
			    				<label for="">Telefon</label>
		      		            <input  v-on:keydown="updateErrors('phone')" type="register-phone" class="form-control" name="phone" v-model="user.phone" v-bind:class="errors.phone?'border-error':''"  placeholder="Numer telefonu">
		      		            <!-- <span class="info-span">Kurier skontaktuje się z Tobą w celu dostarczenia zamówienia</span> -->
		      		        </div>

				      		<div class="form-group" v-show="displayAddress">
				    			<label for="">Adres</label>
				      		    <input  v-on:keydown="updateErrors('address')" type="text" placeholder="Adres" class="form-control" name="address" v-model="user.address" v-bind:class="errors.address?'border-error':''"  autofocus>
				      		</div>
				      		<div class="row">
					      		<div class="col-md-6" style="padding: 0" v-show="displayPostcode">
				      		        <div class="form-group">
						    			<label for="">Kod pocztowy</label>
				      		            <input  v-on:keydown="updateErrors('postcode')" type="register-postcode" class="form-control " name="postcode" v-model="user.postcode" v-bind:class="errors.postcode?'border-error':''"  placeholder="Kod pocztowy">
				      		        </div>
					      		</div>
					      		<div class="col-md-6" style="padding: 0" v-show="displayCity">
				      		        <div class="form-group">
						    			<label for="">Miasto</label>
				      		            <input  v-on:keydown="updateErrors('city')" type="register-city" class="form-control" name="city" v-model="user.city" v-bind:class="errors.city?'border-error':''"  placeholder="Miasto">
				      		        </div>
				      		    </div>
				      		</div>
				    		<span class="info-span font-weight-bold">Wszystkie pola są wymagane</span><br><br>
				    	</div>
				  	</div>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<h2>Podsumowanie</h2>	
			<h5>
			Cena produktów: {{ cartTotal }} zł <br>
			</h5>

			<h5 v-if="total != ''">
				Dostawa: {{ deliveryPrice }}<br>
				<span v-show="this.showDeliveryTime">Czas dostawy: {{ deliveryTimeLabel }}<br></span>
				<span class="font-weight-bold">Razem:</span> {{ total }}
			</h5>

			<button class="btn btn-main w-100 mt1" :disabled="bayError" @click="submit()"
			><span></span> 
			<svg v-show="loading" id="animation-rotate" style="left: 2em" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 11c.511-6.158 5.685-11 12-11s11.489 4.842 12 11h-2.009c-.506-5.046-4.793-9-9.991-9s-9.485 3.954-9.991 9h-2.009zm21.991 2c-.506 5.046-4.793 9-9.991 9s-9.485-3.954-9.991-9h-2.009c.511 6.158 5.685 11 12 11s11.489-4.842 12-11h-2.009z"/></svg>
			Kupuję
			</button>
			<p class="small-text">Kupując, akceptujesz <a href="/regulamin-i-polityka-prywatnosci" target="_blank">regulamin</a> sklepu.</p>

			<div v-if="Object.keys(this.errors).length > 0" class="alert alert-warning mt-3" role="alert">
				<div v-for="(v,k) in errors">

					<span v-for="error in v"> {{ error }}</span><br>
				</div>
			</div>
			<div data-toggle="collapse" data-target="#order" id="order-list">
				<p>Moje zamówienie <span class="float-right">&#x21e9;</span></p>
			</div>

			<div id="order" class="collapse">
		        <div class="row" v-for="(item) in cartItems">
		            <div class="col-md-4">		                
		                <img :src="item.options.image" :alt="item.name" class="w-100">
		            </div>
		            <div class="col-md-8">
	                    <h6 class="product-name">
	                        {{ item.name }}
	                    </h6>
		                <p>
		                    Ilość: {{ item.qty }} <br>                                           
		                    Rozmiar: {{ item.options.sizeLabel }} <br>                                           
		                	{{ item.price * item.qty }} zł
		                </p>    
			            <!-- Sub total -->
		            </div> 
		        </div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        props: [],
        data: function() {
            return{     
                reload: false,
                loading: false,
                bayError: false,
                errors: {},
                couriers: [],
                deliveryPrice: "",
                deliveryTimeLabel: "",
                deliveryTime: "",
                user: [],
                cartItems: "",
                cartTotal: 0,
                total: "",
                payment_id: '',
                courier_id: '',
                courier_type: '',
                inpost_point_code: '',
                payments: [],
                displayAddress: true,
				displayPostcode: true,
				displayCity: true,
				displayDeliveryCalendar: false,
				payment_type: '',
				pickupDate: '',
				pickupDateMin : '',
				pickupTimeHour : '9',
				pickupTimeHourMin : '9',
				pickupTimeHourMax : '13',
				pickupTimeMinute : '00',
				pickupTimeMinuteMin : '0',
				pickupTimeMinuteMax : '59',
				dayAvailable : true,
				showDeliveryTime : true,
				shopInfo : "",
            }
        },
        methods: {
            getCart() {                
                axios
                    .get('/get-cart')
                    .then(response => {
                        this.cartItems = response.data.cart;
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.reload = false)
            },
            getCartTotal() {                
                axios
                    .get('/get-cart-total')
                    .then(response => {
                        this.cartTotal = response.data.total;
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => this.reload = false)
            },
            getCouriers() {  
                this.reload = true;
                axios
                    .post('/checkout-couriers')
                    .then(response => {
                        this.couriers = response.data.couriers;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            getPaymentsMethods() {     
                this.reload = true;
                axios
                    .post('/checkout-payments-methods')
                    .then(response => {
                        this.payments = response.data.payments;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            getUser() {  
                this.reload = true;
                axios
                    .post('/checkout-user')
                    .then(response => {
                    	if(response.data.user){
                        	this.user = response.data.user;
                    	}
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            selectCourier(courier) {  
                axios
                    .post('/checkout-delivery', {
                        courier_id: courier.id
                    })
                    .then(response => {
                    	if(courier.type == "POS"){
                        	this.displayDeliveryCalendar = true;
                        	this.pickupDate = courier.openHours.date;
                        	this.pickupTimeHour = courier.openHours.time.open.hour;
                        	this.pickupTimeHourMin = courier.openHours.time.open.hour;
                        	this.pickupTimeHourMax = courier.openHours.time.close.hour;
                        	this.pickupTimeMinute = courier.openHours.time.open.minute;
                        	this.shopInfo = courier.openHours.time.open.value + "-" +courier.openHours.time.close.value;

                        } else {
            				$("#collapseTwo").collapse('show');
            				this.displayDeliveryCalendar = false;
                        } 
                        this.deliveryPrice = response.data.price + " zł";
                        this.deliveryTimeLabel = response.data.deliveryTimeLabel;
                        this.deliveryTime = response.data.deliveryTime;
                        this.total = (parseFloat(response.data.price, 2) + parseFloat(this.cartTotal, 2)).toFixed(2) + " zł";
                        this.courier_id = courier.id;
                        this.courier_type = courier.type;
                        this.updateErrors('courier_id');
                        if(courier.type == 'INPOST'){
                        	this.openInpostModal();
                        }
						this.showDeliveryTime = true;
                        if(courier.type == "POS"){
                        	this.showDeliveryTime = false;
                        }
                        
                        this.showHiddenInputs("DELIVERY_" + this.courier_type);                        
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            checkDeliveryCalendar(){
            	axios
                    .post('/checkout-delivery-calendar', {
                        date: this.pickupDate,
                    })
                    .then(response => {
                    	if(response.data.message == 'shop-closed'){
                    		this.dayAvailable = false;
                        } else {
                    		this.dayAvailable = true;
                    		this.pickupDate = response.data.date;
                    		this.pickupTimeHour = response.data.time.open.hour;
                    		this.pickupTimeHourMin = response.data.time.open.hour;
                    		this.pickupTimeHourMax = response.data.time.close.hour;
                    		this.pickupTimeMinute = response.data.time.open.minute;
                    		this.shopInfo = response.data.time.open.value + "-" +response.data.time.close.value;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            checkOpenHour(){
            	if(this.pickupTimeHour > this.pickupTimeHourMax){
            		this.pickupTimeHour = this.pickupTimeHourMax;
            		alert("Dziś pracujemy do: "+ this.pickupTimeHourMax);
            	}

            },
            checkMinutes(){
            	if(this.pickupTimeMinute > 59){
            		this.pickupTimeMinute = 0;
            	}

            },
            selectPayment(payment) {  
            	this.showHiddenInputs("PAYMENT_"+payment.name);
                this.payment_type = payment.name;
                this.payment_id = payment.id;
                this.updateErrors('payment_id');                
                $("#collapseOne").collapse('show');                
            },
            showHiddenInputs(selectedOption){

            	switch(selectedOption) {
            		case 'PAYMENT_payment_in_shop':
            			this.couriers.forEach(function(item){
            				if(item.type == "POS"){
            					item.show = 1;
            				} else { item.show = 0; }
            			})

            			this.displayAddress = false;
						this.displayPostcode = false;
						this.displayCity = false;

            		break;
            		case 'PAYMENT_cod':
            			this.couriers.forEach(function(item){
            				if(item.type == "COURIER"){
            					item.show = 1;
            				} else { item.show = 0; }
            			})
            		break;
            		case 'DELIVERY_POS':
            			this.displayAddress = false;
						this.displayPostcode = false;
						this.displayCity = false;
            		break;
            		default:
            			// show all
	            		this.couriers.forEach(function(item){
	            			item.show = 1;
	            		})
	        			this.displayAddress = true;
						this.displayPostcode = true;
						this.displayCity = true;            		
            	}
            },
            submit(){
            	let userAddress = this.user.address;
            	if(this.courier_type == 'INPOST'){
					userAddress = this.inpost_point_code+ " - " +this.user.address;
            	}
            	// validation
            	this.bayError = true;
            	this.loading = true;
            	axios
            	    .post('/checkout-confirm', {
            	        courier_id: this.courier_id,
            	        name: this.user.name,
            	        surname: this.user.surname,
            	        email: this.user.email,
            	        phone: this.user.phone,
            	        address: userAddress,
            	        postcode: this.user.postcode,
            	        city: this.user.city,
            	        payment_id: this.payment_id,
            	        payment_type: this.payment_type,
            	        courier_type: this.courier_type,
            	        delivery_time: this.deliveryTime,
            	        pickup_date: this.pickupDate,
						pickup_time_hour: this.pickupTimeHour,
						pickup_time_minute: this.pickupTimeMinute,
            	    })
            	    .then(response => {
            	        if(response.data.error){
            	        	this.errors = response.data.data;
            	        }

            	        if(response.data.success){
            	        	window.location.href = response.data.redirectRoute;
            	        }
            	        this.loading = false;
            	    })
            	    .catch(error => {
            	    });
            },  
            updateErrors(item){
            	if(this.errors[item]){
            		this.$delete(this.errors, item);
            	}

            	if(Object.keys(this.errors).length == 0){
            		this.bayError = false;
            	}            
            },
            initInpostMap(){
            	window.easyPackAsyncInit = function () {
		            easyPack.init({
		                defaultLocale: 'pl',
		                mapType: 'osm',
		                searchType: 'osm',
		                points: {
		                    types: ['parcel_locker_only']
		                },
		                map: {
		                    initialTypes: ['parcel_locker_only']
		                }
		            });
		    	};
            },
            openInpostModal() {
	            easyPack.modalMap( (point, modal) => {
	              modal.closeModal();
	              this.inpost_point_code = point.name;
	              this.user.address = point.address.line1;
	              this.user.postcode = point.address_details.post_code;
	              this.user.city = point.address_details.city;
	            }, { width: document.getElementById("checkout-row").offsetWidth, height: 600 });
	            $("#collapseTwo").collapse('show');
	        }
        },
        created() {
        	this.getPaymentsMethods();
        	this.getCouriers();
            this.getCartTotal();
            this.getUser();
            this.getCart();
            this.initInpostMap();
        },
    }
</script>