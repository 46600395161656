<template>
    <div>
        <div v-if="promo" class="price-container">
            <span class="price">{{ productPrice }} zł</span>
            <span class="price-cat">{{ product.price }} </span>
            <p class="info-span">                
                Najniższa cena w ciągu ostatnich 30 dni: {{ product.price }} zł
            </p>
            <br>
        </div>
        <div v-else class="price-container">
            <span class="price">{{ productPrice }} zł</span>
            <br><br>
        </div> 
        <div class="add-to-cart-container">
            <div v-if="sizes.find(o => o.size_id == 999999) != undefined">
                <input type="hidden" v-model="selectSize" value="999999">
            </div>
            <div v-else>  
                <select v-model="selectSize" @change=selectedSize class="form-control size mt1">       
                    <option disabled="true">Rozmiar</option>                                
                    <option v-for="(item) in sizes" :value="item">{{ item.size.name }} {{ (item.price && item.price != 0)?"(" + item.price + "zł)":"" }}</option>
                </select>
            </div>
            <div v-show=sizeInfo class="alert alert-info mt1" role="alert">
                Rozmiar
            </div>   
            <input type="hidden" name="img" v-model="img">     

            <div class="row" v-show="addons.length > 0">
                <div class="col-md-4 addons" :class="selectedAddon(addon.addon_id)" @click="changeSelectedAddons(addon)" v-for="(addon) in addons" >{{ addon.addon.name }} +{{ addon.addon.price }}zł</div>
            </div>

            <button class="btn btn-main w-100 mt1" :disabled="loading" @click="addToCart($event)">
                <svg v-show="loading" id="animation-rotate" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 11c.511-6.158 5.685-11 12-11s11.489 4.842 12 11h-2.009c-.506-5.046-4.793-9-9.991-9s-9.485 3.954-9.991 9h-2.009zm21.991 2c-.506 5.046-4.793 9-9.991 9s-9.485-3.954-9.991-9h-2.009c.511 6.158 5.685 11 12 11s11.489-4.842 12-11h-2.009z"/></svg>
             Dodaj do koszyka</button>
            <div v-show="cartError" class="alert alert-warning alert-dismissible fade show mb-2" role="alert">
                <strong>Uwaga</strong> {{ cartError}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import EventListener from './../../../app.js';
    export default {
        props: [ 'promo', 'product', 'sizes', 'img', 'addons' ],
        data: function() {
            return{     
                selectSize: "Rozmiar",
                selectSizeLabel: "",
                sizeInfo: false,
                loading: false,
                productPrice: 0,
                productPriceMain: 0,
                totalAddonsPrice: 0,
                selectedAddons: [],
                cartError: false,
            }
        },

        methods: {
            addToCart(e) {
                if(this.selectSize == "Rozmiar"){
                    this.sizeInfo = true;
                }else{     
                    // disable button
                    this.loading = true;
                    axios
                        .post('/add-to-cart', {
                            id: this.product.id,
                            name: this.product.name,
                            delivery_time: this.product.delivery_time,
                            img: this.img,
                            price: this.productPrice,
                            size: this.selectSize,
                            addons: this.selectedAddons,
                            path: window.location.pathname,
                        })
                        .then(response => {                            
                            if(response.data.success){
                                EventListener.$emit('add-to-cart');
                            } else {
                                this.cartError = response.data.message;
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                        .finally(() => this.loading = false)
                }
            },
            selectedSize(){
                if (this.selectSize.price && this.selectSize.price != 0) {
                    this.productPrice = this.selectSize.price;
                } else { this.productPrice = this.productPriceMain; }
                this.sizeInfo = false;
            },
            changeSelectedAddons(addon){
                // check object exist
                let addonExist = this.selectedAddons.filter(obj => {
                  return obj.id === addon.addon_id
                })

                if(addonExist.length){
                    this.selectedAddons = this.selectedAddons.filter(function( obj ) {
                      return obj.id !== addon.addon_id;
                    });                    
                } else {
                    this.selectedAddons.push({
                        id: addon.addon.id,
                        name: addon.addon.name,
                        price: addon.addon.price,
                    });
                }

                this.productPrice = this.calculateAddonsPrice(this.productPriceMain).toFixed(2);   
            },
            calculateAddonsPrice(currentPrice){
                this.totalAddonsPrice = 0;
                this.selectedAddonsList = [];
                if(this.selectedAddons.length > 0){
                    this.addons.forEach((el) => {
                        let addonExist = this.selectedAddons.filter(obj => {
                          return obj.id === el.addon_id
                        })
                        if(addonExist.length){
                            this.totalAddonsPrice += Math.round(el.addon.price * 100) / 100;
                        }
                    })
                }
                return (Math.round(currentPrice * 100) / 100) + this.totalAddonsPrice;
            },
            selectedAddon(addon_id){
                let addonExist = this.selectedAddons.filter(obj => {
                  return obj.id === addon_id
                })
                if(addonExist.length){
                    return 'addon-selected';
                }
                return '';
            }
        },
        created() {
            this.productPrice = this.product.price;
            this.productPriceMain = this.product.price;
            if(this.promo) {
                this.productPrice = this.product.price_promo;
                this.productPriceMain = this.product.price_promo;
            }
            if(this.sizes.length==1){
                this.selectSize = this.sizes[0];
            }
        },
    }
</script>